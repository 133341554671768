import { sortBy, isEqual } from "lodash";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

class ManageSchedule {
  message = "This {MOD_TYPE} will repeat {TYPE} starting on {START_DATE_TIME} and repeat {OCCURRENCE} time(s) until {END_DATE_TIME}";

  oneTimeMessage = "This {MOD_TYPE} will start on {START_DATE_TIME} and finish on {END_DATE_TIME}";

  static getEndDate (param) {
    if (param.end_mode == 3 || param?.type?.group == 'onetime') {
      return moment(param.end_date).format("YYYY-MM-DD");
    }

    return null;
  }
  
  constructor(scheduleType, startDate, occurrences, endDate = null, endMode = 2, pattern = null, weekDay = null, weekCount = null) {
    this.scheduleType = scheduleType;
    this.startDate = new Date(startDate);
    this.occurrences = occurrences > 0 ? occurrences : 1;
    this.endMode = endMode;
    this.endDate = (scheduleType == "onetime" || (endMode == 3 && endDate)) ? new Date(endDate) : null;
    this.pattern = pattern ? parseInt(pattern, 10) : 1;
    this.weekDay = weekDay ? parseInt(weekDay, 10) - 1 : -1;
    this.weekCount = weekCount ? parseInt(weekCount, 10) - 1 : -1;
  }

  generateOneTimeSchedule() {
    const schedule = [];

    if (!this.startDate || !this.endDate) {
      return schedule;
    }

    schedule.push(this.formatDate(this.startDate));
    /* schedule.push(this.formatDate(this.endDate)); */

    return schedule;
  }

  generateMonthlySchedule(interval) {
    const schedule = [];
    let currentDate = this.startDate;
    let breakCounter = 0;

    if (!this.occurrences && !this.endDate) {
      return schedule;
    }

    if (this.endMode == 2 && typeof this.occurrences === 'number') {

      for (let i = 0; i < this.occurrences; i++) {
        if (this.pattern == 2 && interval == 12 && i > 0) {
          currentDate.setMonth(0);
          currentDate.setDate(1);
        }

        const date = this.getWeekDay(currentDate, this.weekDay, this.weekCount);
        schedule.push(this.formatDate(date));
        currentDate.setMonth(currentDate.getMonth() + interval);
      }

    } else if (this.endMode == 3 && this.endDate instanceof Date) {

      while (currentDate <= this.endDate) {
        if (this.pattern == 2 && interval == 12 && breakCounter > 0) {
          currentDate.setMonth(0);
          currentDate.setDate(1);
        }

        const date = this.getWeekDay(currentDate, this.weekDay, this.weekCount);
        schedule.push(this.formatDate(date));
        currentDate.setMonth(currentDate.getMonth() + interval);
        breakCounter++;

        if (breakCounter > 500) {
          break;
        }
      }

    } 

    return schedule;
  }

  combineDate(scheduleDate, endTime) {
    const schedule = [];

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const [hour, minute, second] = endTime.split(":");

    for (let i = 0; i < scheduleDate.length; i++) {
      const startDateInstance = new Date(scheduleDate[i]);
      const endDateInstance = new Date(scheduleDate[i]);

      endDateInstance.setHours(hour);
      endDateInstance.setMinutes(minute);
      endDateInstance.setSeconds(second);

      schedule.push({
        daysOfWeek: daysOfWeek[startDateInstance.getDay()],
        startDateTime: this.formatDate(startDateInstance),
        endDateTime: this.formatDate(endDateInstance),
        startDate: this.formatDate(startDateInstance),
        endDate: this.formatDate(endDateInstance),
        startTime: this.formatTime(startDateInstance),
        endTime: this.formatTime(endDateInstance),
        status: 1,
        isEdited: 0,
      });
    }

    return schedule;
  }

  formatDateTime(datetime) {
    const year = datetime.getFullYear();
    const month = String(datetime.getMonth() + 1).padStart(2, "0");
    const day = String(datetime.getDate()).padStart(2, "0");
    const hours = String(datetime.getHours()).padStart(2, "0");
    const minutes = String(datetime.getMinutes()).padStart(2, "0");
    const seconds = String(datetime.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  formatTime(time) {
    return time.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  scheduleCompare(prevObject, newObject) {
    return prevObject?.ticket?.schedule_message == newObject?.message;
  }

  teamCompare(prevObject, newObject) {
    const prevTeam = prevObject?.team?.map((row) => row.engineer_id);
    return isEqual(sortBy(prevTeam), sortBy(newObject));
  }

  getMessage (type, schedule, startDate, endDate, scheduleLen) {
    if (schedule?.type?.value == "onetime") {
      return String(this.oneTimeMessage).replace("{MOD_TYPE}", type).replace("{START_DATE_TIME}", startDate).replace("{END_DATE_TIME}", endDate);
    }

    return String(this.message).replace("{MOD_TYPE}", type).replace("{TYPE}", schedule?.type?.text).replace("{START_DATE_TIME}", startDate).replace("{OCCURRENCE}", scheduleLen).replace("{END_DATE_TIME}", endDate);
  }

  getWeekDay(param, weekDay, weekCount) {
    const date = new Date(param);

    if (this.pattern !== 2 || weekDay < 0 || weekCount < 0) {
      return date;
    }

    const year = date.getFullYear();
    const month = date.getMonth();
    const days = [];

    const lastDayOfMonth = new Date(year, month + 1, 0);

    for (let day = 1; day <= lastDayOfMonth.getDate(); day++) {
      const currentDate = new Date(year, month, day);
      if (currentDate.getDay() === weekCount) {
        days.push(currentDate);
      }
    }

    let result = date;

    if (weekDay === 4) {      
      result = days[days.length - 1];
    }

    if (weekDay >= 0 && weekDay < days.length) {
      result = days[weekDay];
    }

    const defaultDate = new Date(this.startDate);

    if (defaultDate > result) {
      defaultDate.setMonth(defaultDate.getMonth() + 1);
      result = this.getWeekDay(defaultDate, weekDay, weekCount);
    }

    return result;
  }
}

export default ManageSchedule;
